import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from './globalservice';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl = GlobalService.getBasepath() + '/api';
  // "target":"https://player.go2market.in:5000"
  // "target":"http://52.66.18.185:5000",
  constructor(private _http: HttpClient) { }
  getLoggedInUser(): any | undefined {
    let loginRes = localStorage.getItem('login');
    if (loginRes && loginRes !== null && loginRes !== '') {
      try {
        return JSON.parse(loginRes);
      } catch (error) {
        return undefined;
      }
    }
    return undefined;
  }
  getNavigationAgent() {
    let agentdeptroute = localStorage.getItem('agentdeptRoute');
    if (agentdeptroute && agentdeptroute !== null && agentdeptroute !== '') {
      try {
        return JSON.parse(agentdeptroute);
      } catch (error) {
        return undefined;
      }
    }
    return undefined;
  }
  getNavigationAdmin(){
    let adminRoute = localStorage.getItem('adminRoute');
    if (adminRoute && adminRoute !== null && adminRoute !== '') {
      try {
        return JSON.parse(adminRoute);
      } catch (error) {
        return undefined;
      }
    }
    return undefined;
  }
  public Login(req): Observable<any> {
    return this._http.post(`${this.baseUrl}/login`, req);
  }
  public getCaptcha(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/GetCaptcha`);
  }
  public logout(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/logout`);
  }

}
