import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GlobalAlertServiceService {
    private msg: Subject<any> = new Subject<any>();
    private usrmessage: Subject<any> = new Subject<any>();
    private customerinventoryuploaddata: Subject<Array<any>> = new Subject<Array<any>>();
    private confirMsg: Subject<any> = new Subject<any>();
    constructor() { }
    public alert(message:any){
        console.log("message",message);
        
      this.msg.next(message)
    }
    public onMessage():Observable<any>{
      return this.msg.asObservable()
    }
    public confirmbox(message:any){
       this.confirMsg.next(message)
    }
    public onConfirmMsg():Observable<any>{
      return this.confirMsg.asObservable()
    }
}
