<div (contextmenu)="onRightClick($event)">
  <router-outlet></router-outlet>
</div>
<div class="modal" id="hide" bsModal [config]="{ show: false ,backdrop: 'none'}"  #popupSuccessModel="bs-modal"  tabindex="-1">
    <div class="modal-dialog dialog me-3" id="hideMeAfter5Seconds">
      <div class="modal-content">
        <div class="modal-body p-0"  [ngClass]="alert.type" role="alert">
          <div class="container-fluid">
            <div class="row pt-1" style="color: black;">
              <div class="col-1">
                <i *ngIf="alert.type=='alert alert-danger'" class="fas fa-exclamation-triangle"></i>
                <i *ngIf="alert.type!='alert alert-danger'" class="fas fa-solid fa-check"></i>
              </div>
              <div class="col-9" style="align-self: center;">
                <strong *ngIf="alert.type=='alert alert-danger'">Error!</strong>
                <strong *ngIf="alert.type!='alert alert-danger'">Success</strong>
              </div>
              <div style="cursor: pointer;" class="col-2" (click)="popupSuccessModel.hide()">
                <div class="text-end">
                  <i class="fa fa-times onHover" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-1">
              </div>
              <div class="col-9" style="font-size: 16px;">
                <label class="pb-3" [innerHTML]="alert.message"> </label>
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>